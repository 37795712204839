import React from "react";
import VacancyMobile from "./VacancyMobile";
import VacancyDesktop from "./VacansyDesktop";


const VacancyBody = ({vacancyOne, isShow}) => {

let isDesktop = false

if (isDesktop) {

    return (
       <VacancyDesktop vacancyOne={vacancyOne}/>
    )
}

else {
    return (
        
      <VacancyMobile vacancyOne={vacancyOne} isShow={isShow}/>
        
    )
}

}

export default VacancyBody