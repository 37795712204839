import React from "react";


const VacancyMobile = ({vacancyOne, isShow}) => {



        return (
            <div className={isShow ? "vacancy__description" : "vacancy__description vacanscy_isShow" }>
            <div className="vacancy__header">
              <div className="vacancy__title">{vacancyOne.name}</div>
              <div className="vacancy__salary">
                Заработная плата: <span>{vacancyOne.salary} </span>₽
              </div>
            </div>

            <div className="vacancy__responsibilities">
              <div className="vacancy__subtitle">Обязанности:</div>
              <div
                dangerouslySetInnerHTML={{
                  __html: vacancyOne.responsibilities.data.internal.content,
                }}
              />
            </div>
            <div className="vacancy__requirements mt-30">
              <div className="vacancy__subtitle">Требования:</div>
              <div
                dangerouslySetInnerHTML={{
                  __html: vacancyOne.requirements.data.internal.content,
                }}
              />
            </div>

            <div className="vacancy__conditions mt-30">
              <div className="vacancy__subtitle">Условия:</div>
              <ul>
                <li>
                  <p>работа на территории компании;</p>
                </li>
                <li>
                  <p>оформление по ТК РФ;</p>
                </li>
                <li>
                  <p>работа в Ломоносовском районе д. Глядино</p>
                </li>
                <li>
                  <p>график работы: пн-чт 09.00 - 18.00, пт 09.00 - 17.00</p>
                </li>
                <li>
                  <p>
                    бесплатная развозка от ст.м «Купчино», «Проспект Ветеранов»,
                    «Парнас»;
                  </p>
                </li>
                <li>
                  <p>бесплатное питание;</p>
                </li>
                <li>
                  <p>молодой, дружный коллектив, демократичная обстановка.</p>
                </li>
              </ul>
            </div>
          </div>
          
        )


}

export default VacancyMobile;