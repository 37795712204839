import React, {  useState } from "react";
import Layout from "../components/layout";
import { useStaticQuery, graphql } from "gatsby";
import VacancyBody from "../components/Vavansy/VacansyBody";
import SvgIcons from "../components/svgIcons/svgIcons";
import { useMediaQuery } from 'react-responsive';

const Vacancy = () => {
  const data = useStaticQuery(graphql`
    query VacancyQuery {
      allStrapiVacancy {
        nodes {
          name
          salary
          strapi_id
          requirements {
            data {
              internal {
                content
              }
            }
          }
          responsibilities {
            data {
              id
              internal {
                content
              }
            }
          }
        }
      }
    }
  `);

  const [vacancyOne, setVacancyOne] = useState(data.allStrapiVacancy.nodes[0]);
  function selectVacancy(vacancy) {

    setVacancyOne(vacancy);
    setShow(true);
  }
 
  let [isShow, setShow] = useState(false);
  function closeVacancies () {
    setShow(false);
  }
  const isDesktop = useMediaQuery({ query: '(min-width: 577px)' })
  return (
    <Layout>
      <div className="container content_header  mt-60">
        <h1 className="text-center">
          ВАКАНСИИ КОМПАНИИ DENDOR В САНКТ-ПЕТЕРБУРГЕ:
        </h1>
        <div className="info mt-30 mb-30 ">
          <p className="p20">
            По всем вопросам трудоустройства обращайтесь к{" "}
            <b>Куликову Александру Анатольевичу</b>
            <br />
            по тел: +7 (812) 640-30-30 доб. 727 или +7-906-247-30-53
            <br />
            Резюме Вы можете отправить на адрес электронной почты:
            kulikov@dendor.ru
          </p>
        </div>
      </div>

      <div className="container mt-30">
        <div className="vacancy">
          <div className="vacancy__name">
            <div className="vacancy__title">Вакансии</div>
            <ul>
              {data.allStrapiVacancy.nodes.map((vacancy) => {
                return (
                  <li
                    key={vacancy.strapi_id}
                    onClick={() => selectVacancy(vacancy)}
                  >
                    {vacancy.name}
                  </li>
                );
              })}
            </ul>
          </div>
          {isShow &&   (!isDesktop && <div className="mobileCloseVacancies">
          <div className="logo">
                <a href="/">
                  <SvgIcons width="200" height="35" name="dendor" />
                </a>
              </div>
          <div className="btnCloseVacancy">    <span onClick={()=> closeVacancies()} className="">X</span></div>
              </div> ) }
          <VacancyBody vacancyOne={vacancyOne} isShow = {isShow}/>
        </div>
      </div>
    </Layout>
  );
};

export default Vacancy;
